import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { BlogEntry } from "../../../components";
import { DesktopRightImage, PhoneImage } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Parts of the alps are in the south of Germany. But most of the mountain
biking is in the lower ranges called Mittelgebirge. You don’t need steep
mountains for great biking and there is plenty of it all over Germany.`}</p>
    <br />
    <Link className="text-black" to="/destination/germany/freiburg" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/freiburg2.jpg" mdxType="DesktopRightImage" />
        <h2>{`Freiburg`}</h2>
        <h4>{`A city full of bike-crazy people`}</h4>
        <p>{`A great place to ride in the low mountain ranges of Germany? Yes!
The Black Forest has more to offer than you think. Long descents up to
almost 1000vm and a huge density of singletrails right next to the city.`}</p>
        <PhoneImage src="destinations/freiburg2.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/germany/willingenbrilon" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/willingenbrilon_9.jpg" mdxType="DesktopRightImage" />
        <h2>{`Willingen & Brilon`}</h2>
        <h4>{`Center of mountain biking in the Sauerland`}</h4>
        <p>{`Well known for its bikepark, you will be alone on the trails in the
surrounding hills and valleys. Easy and flowy trails make it perfect for
beginners and intermediate riders.`}</p>
        <PhoneImage src="destinations/willingenbrilon_9.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      